@charset "UTF-8";

// reset
@import "./core/_reset";

// core
@import "./core/_font";
@import "./core/_variable";
@import "./core/_mixin";
@import "./core/_layout";

// libs
@import "libs/_slick.scss";

// common sp
@import "common/footer/_footer_sp.scss";
@import "common/header/_header_sp.scss";
// module sp
@import "module/backToTop/_mod-backToTop_sp.scss";
@import "module/breadcrumb/_mod-breadcrumb_sp.scss";
@import "module/btn/_mod-btn_sp.scss";
@import "module/form/_mod-form_sp.scss";
@import "module/heading/_mod-heading_sp.scss";
@import "module/inner/_mod-inner_sp.scss";
@import "module/link/_mod-link_sp.scss";
@import "module/number/_mod-number_sp.scss";
@import "module/primaryTtl/_mod-primaryTtl_sp.scss";
@import "module/secondaryTtl/_mod-secondaryTtl_sp.scss";
@import "module/table/_mod-table_sp.scss";
@import "module/toc/_mod-toc_sp.scss";
// page sp
@import "page/about/_about_sp.scss";
@import "page/brand/_brand_sp.scss";
@import "page/catchphrase/_catchphrase_sp.scss";
@import "page/contact/_contact_sp.scss";
@import "page/english/_english_sp.scss";
@import "page/links/_links_sp.scss";
@import "page/news/_news_sp.scss";
@import "page/privacy/_privacy_sp.scss";
@import "page/product/_product_sp.scss";
@import "page/top/_top_sp.scss";

@media only screen and (min-width: $breakpoint) {
  // common pc
  
@import "common/footer/_footer_pc.scss";
@import "common/header/_header_pc.scss";
  // module pc
  
@import "module/backToTop/_mod-backToTop_pc.scss";
@import "module/breadcrumb/_mod-breadcrumb_pc.scss";
@import "module/btn/_mod-btn_pc.scss";
@import "module/form/_mod-form_pc.scss";
@import "module/heading/_mod-heading_pc.scss";
@import "module/inner/_mod-inner_pc.scss";
@import "module/link/_mod-link_pc.scss";
@import "module/number/_mod-number_pc.scss";
@import "module/primaryTtl/_mod-primaryTtl_pc.scss";
@import "module/secondaryTtl/_mod-secondaryTtl_pc.scss";
@import "module/table/_mod-table_pc.scss";
@import "module/toc/_mod-toc_pc.scss";
  // page pc
  
@import "page/about/_about_pc.scss";
@import "page/brand/_brand_pc.scss";
@import "page/catchphrase/_catchphrase_pc.scss";
@import "page/contact/_contact_pc.scss";
@import "page/english/_english_pc.scss";
@import "page/links/_links_pc.scss";
@import "page/news/_news_pc.scss";
@import "page/privacy/_privacy_pc.scss";
@import "page/product/_product_pc.scss";
@import "page/top/_top_pc.scss";
}