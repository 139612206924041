/* --------------------------------------------
   top_sp
----------------------------------------------- */

#top {
  .mainVisual {
    position: relative;

    &__logo {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 143px;
      height: auto;
      padding: 26px 7px;
      background: #FFFFFF;
      z-index: 2;
    }

    &__slider {
      position: relative;
      z-index: 1;

      .slider {
        &__items {
          line-height: 0;
          opacity: 0;
          transition: all 1s ease-out 0s;

          &.slick-initialized {
            opacity: 1;
          }
        }
      }
    }
  }

  .brand {
    padding: 190px 0 80px 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 53.65%, rgba(255, 252, 242, 0.88) 76.83%, rgba(255, 246, 214, 1) 100%);

    &__ttl {
      position: relative;
      width: 85.32%;
      max-width: 450px;
      margin: 0 auto;
      padding: 122px 40px 33px 35px;
      background: #FFFFFF;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      
      span {
        position: relative;
        z-index: 2;

        &::after {
          content: '';
          display: block;
          clear: both;
        }
        
        span {
          float: left;
          font-size: 3.4rem;
          font-weight: 700;
          line-height: 1.18;
          color: #333333;
          
          &:first-child {
            margin: 0 0 5px -15px;
          }

          &:nth-child(2) {
            clear: both;
          }
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 1;
      }

      &::before {
        top: -56px;
        left: -25px;
        width: 135px;
        height: 170px;
        background-image: url(/assets/img/top/ttl-brand-catchphrase.png);
      }
      
      &::after {
        bottom: -24px;
        right: -16px;
        width: 136px;
        height: 82px;
        background-image: url(/assets/img/top/ttl-brand-product.png);
      }
    }

    &__content {
      width: 100%;
      max-width: 1000px;
      margin: -28px auto 0 auto;
      padding: 65px 24px 56px 24px;
      background: #F5F2E9;
    }

    &__txt {
      margin: 0 0 36px 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.93;
      letter-spacing: 0.015em;
      color: #333333;
    }
  }

  .product {
    padding: 132px 0 82px 0;
    background: #3B803B;

    &__ttl {
      margin: 0 0 29px 0;
      font-size: 2.4rem;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;

      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 27px;
        height: 27px;
        margin: 0 8px 0 0;
        background-image: url(/assets/img/common/ico-heart-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -15px -0.5px calc(-15px + 46px) -0.5px;
    }
    
    &__item {
      width: 50%;
      padding: 15px 0.5px;
    }

    &__desc {
      display: flex;
      flex-direction: column;
    }

    &__term {
      order: 2;
      font-size: 1.4rem;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
    }

    &__dfn {
      order: 1;
      min-height: 0%;
      margin: 0 0 13px 0;
    }
  }
  
  .news {
    padding: 75px 0 80px 0;

    &__ttl {
      margin: 0 0 29px 0;
      font-size: 2.4rem;
      font-weight: 700;
      text-align: center;
      color: #333333;
    }

    &__list {
      margin: 0 0 48px 0;     
    }

    &__item {
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }

    &__desc {
      position: relative;
      padding: 14px 52px 16px 15px;
      border: solid 1px #D5D5D5;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translate(0, -50%);
        display: block;
        width: 6px;
        height: 10px;
        background-image: url(/assets/img/common/ico-arrow-black.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &__term {
      margin: 0 0 5px 0;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.44;
      color: #4A4A4A;
    }

    &__date {
      margin: 0 0 14px 0;
      font-size: 1.2rem;
      font-weight: 400;
      color: #4A4A4A;
    }

    &__category {
      .category {
        &__items {
          margin: -2px;
          font-size: 0;
        }

        &__item {
          display: inline-block;
          margin: 2px;
          padding: 1px 12px;
          font-size: 1rem;
          font-weight: 500;
          color: #4A4A4A;
          border: solid 2px #000;
          border-radius: 100px;

          &--01 {
            border-color: #3B803B;
          }

          &--02 {
            border-color: #F09400;
          }

          &--03 {
            border-color: #0B73DB;
          }
        }
      }
    }
  }

  .about {
    padding: 80px 0 79px 0;
    background-image: url(/assets/img/top/bac-about_sp.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__inner {
      max-width: 666px;
      padding: 48px 24px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
    }

    &__ttl {
      margin: 0 0 21px 0;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1.46;
      text-align: center;
      color: #3B803B;
    }

    &__txt {
      margin: 0 0 28px 0;
      font-size: 0;
      text-align: center;

      span {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.93;
        color: #333333;
      }
    }
  }

  .banner {
    padding: 80px 0;
    background: #F7F5EF;
    
    &__inner {
      max-width: 788px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -12px;
    }

    &__item {
      width: 50%;
      padding: 12px;
      
      &--emphasis {
        a {
          position: relative;
          box-shadow: 4px 4px 0 rgba(125, 126, 113, 0.5);

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border: solid 2px #FFE200;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}