/* --------------------------------------------
   mod-heading_sp
----------------------------------------------- */

.mod-heading {
  width: 100%;
  height: 184px;
  margin: 0 0 49px 0;
  background: #F7F5EF;

  .heading {
    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }

    &__ttl {
      font-size: 2.4rem;
      font-weight: 700;
      text-align: center;
      color: #4A4A4A;
    }
  }
}