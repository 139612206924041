/* --------------------------------------------
   about_pc
----------------------------------------------- */

#about {
  .main {
    padding: 106px 0 120px 0;
  }

  .about {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 32px auto;
          letter-spacing: 0;
        }

        &__content {
          &::after {
            content: '';
            display: block;
            clear: both;
          }
        }

        &__txt {
          margin: 0 0 31px 0;
          font-size: 1.4rem;
          line-height: 2;
          letter-spacing: 0;
        }

        &__img {
          &--chairperson {
            float: right;
            margin: 0 0 130px 41px;
          }
        }

        &__qanda {
          margin: 0 0 24px 0;
          padding: 33px 36px;

          .qanda {
            &__ttl {
              margin: 0 0 39px 0;
              padding: 0 0 0 61px;
              text-align: left;

              &::before {
                top: calc(1.8rem * 1.44 / 2);
                left: 0;
                transform: translate(0, -50%);
                width: 48px;
                height: 48px;
                padding: 0 0 7px 0;
                font-size: 3.2rem;
              }
            }

            &__content {
              padding: 0 0 0 61px;

              &::before {
                top: -4px;
                left: 0;
                transform: translate(0, 0);
                width: 48px;
                height: 48px;
                padding: 0 0 7px 0;
                font-size: 3.2rem;
              }
            }

            &__item {
              font-weight: 700;
            }

            &__table {
              overflow-x: visible;
              margin: 0 25px 0 -61px;

              tbody {
                .table {
                  &__header {
                    width: 256px;
                    min-width: auto;
                    max-width: none;
                    padding: 20px 17px;
                  }

                  &__data {
                    width: 643px;
                    min-width: auto;
                    max-width: none;
                    padding: 12px 17px;
                  }
                }
              }
            }
          }
        }

        &__head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 12px 0;
        }

        &__lead {
          margin: 0 auto 0 0;
        }

        &__date {
          margin: 0 0 0 auto;
        }

        &__table {
          overflow-x: visible;

          tbody {
            .table {
              &__header {
                width: 325px;
                min-width: auto;
                max-width: none;
                padding: 21px 25px;
              }

              &__data {
                width: 676px;
                min-width: auto;
                max-width: none;

                .dataTable {
                  &__header,
                  &__data {
                    padding: 3px 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}