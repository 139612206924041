// --------------------------------------------------------------------------------
//    font
// --------------------------------------------------------------------------------

/* NotoSansCJKjp */
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 400;
  src:
    local('NotoSansCJKjp-DemiLight'),
    url('../fonts/NotoSansCJKjp-DemiLight.woff2') format('woff2'),
    url('../fonts/NotoSansCJKjp-DemiLight.woff') format('woff'),
    url('../fonts/NotoSansCJKjp-DemiLight.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 500;
  src:
    local('NotoSansCJKjp-Medium'),
    url('../fonts/NotoSansCJKjp-Medium.woff2') format('woff2'),
    url('../fonts/NotoSansCJKjp-Medium.woff') format('woff'),
    url('../fonts/NotoSansCJKjp-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 700;
  src:
    local('NotoSansCJKjp-Bold'),
    url('../fonts/NotoSansCJKjp-Bold.woff2') format('woff2'),
    url('../fonts/NotoSansCJKjp-Bold.woff') format('woff'),
    url('../fonts/NotoSansCJKjp-Bold.otf') format('opentype');
}