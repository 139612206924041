/* --------------------------------------------
   top_pc
----------------------------------------------- */

#top {
  .mainVisual {
    &__logo {
      top: 50%;
      width: 178px;
      padding: 33px 8px;
    }
  }

  .brand {
    padding: 82px 0 80px 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 24.36%, rgba(255, 252, 242, 0.88) 62.18%, rgba(255, 246, 214, 1) 100%);

    &__ttl {
      width: 38%;
      max-width: 380px;
      margin: 0 auto 0 0;
      padding: 165px 29px 126px 37px;

      span {
        span {
          &:first-child {
            margin: 0 0 9px -14px;
          }
        }
      }

      &::before {
        top: -41px;
        left: -20px;
        width: 152px;
        height: 191px;
      }
      
      &::after {
        bottom: -17px;
        right: -24px;
        width: 229px;
        height: 138px;
      }
    }

    &__content {
      width: calc(100% - 38% + 80px);
      max-width: 700px;
      margin: -299px 0 0 auto;
      padding: 53px 56px 56px 136px;
    }

    &__btn {
      max-width: 240px;
      margin: 0 0 0 auto;
    }
  }

  .product {
    padding: 77px 0 80px 0;

    &__ttl {
      margin: 0 0 45px 0;

      &::before {
        margin: 0 16px 0 0;
      }
    }

    &__list {
      margin: -1px -1px calc(-1px + 46px) -1px;
    }

    &__item {
      width: 14.28%;
      padding: 1px;
    }

    &__dfn {
      margin: 0 0 14px 0;
    }
  }

  .news {
    padding: 77px 0 80px 0;

    &__ttl {
      margin: 0 0 38px 0;
    }

    &__desc {
      padding: 14px 130px 16px 15px;

      &::after {
        right: 30px;
      }
    }

    &__term {
      color: #333333;
    }

    &__date {
      color: #333333;
    }
  }

  .about {
    padding: 80px 0;
    background-image: url(/assets/img/top/bac-about_pc.jpg);

    &__inner {
      padding: 48px 64px;
    }

    &__ttl {
      margin: 0 0 17px 0;
      font-size: 3.2rem;
    }

    &__txt {
      margin: 0 0 44px 0;
    }
  }

  .banner {
    &__item {
      width: 25%;
    }
  }
}