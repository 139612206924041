/* --------------------------------------------
   header_sp
----------------------------------------------- */

.header {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background: #FFFFFF;
  z-index: 100;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__logo {
    width: auto;
    margin: 0 auto 0 0;
    
    a {
      position: relative;
      padding: 0 0 0 34px;
      font-size: 0;

      span {
        display: inline-block;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.28;
        color: #000000;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        display: block;
        width: 26px;
        height: 26px;
        background-image: url(/assets/img/common/ico-heart.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }

  &__nav {
    margin: 0 0 0 24px;

    .nav {
      &__btn {
        width: 34px;
        height: 22px;

        button {
          position: relative;
          width: 100%;
          height: 100%;
          outline: none;

          &::before,
          &::after,
          span {
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 3px;
            text-indent: -9999px;
            background: #333333;
            transition: all 0.5s ease-out 0s;
          }

          &::before {
            top: 0;
            transform: translate(0, 0) rotate(0);
          }

          &::after {
            top: 100%;
            transform: translate(0, -100%) rotate(0);
          }

          span {
            top: 50%;
            transform: translate(0, -50%) rotate(0);
          }

          &:hover {
            opacity: 1;
          }
        }

        &.is-open {
          button {
            &::before,
            &::after {
              top: 50%;
              left: 50%;
              width: 114.53%;
            }
  
            &::before {
              transform: translate(-50%, -50%) rotate(30deg);
            }
  
            &::after {
              transform: translate(-50%, -50%) rotate(-30deg);
            }
  
            span {
              width: 0;
            }
          }
        }
      }

      &__content {
        display: none;
        position: fixed;
        top: 56px;
        left: 0;
        width: 100%;
        height: calc(100% - 56px);
        background: #F7F5EF;
        background-image: url(/assets/img/common/logo-catchphrase-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__outer {
        display: flex;
        flex-direction: column;
      }

      &__inner {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 56px);
        padding: 73px 0 80px 0;
      }

      &__primaryMenu {
        margin: 0 0 67px 0;

        .primaryMenu {
          &__item {
            margin: 0 0 27px 0;
            
            &:last-child {
              margin: 0;
            }

            a {
              font-size: 1.8rem;
              font-weight: 700;
              text-align: center;
              color: #4A4A4A;
            }
          }
        }
      }

      &__secondaryMenu {
        margin: 0 0 40px 0;

        .secondaryMenu {
          &__item {
            margin: 0 0 16px 0;
            
            &:last-child {
              margin: 0;
            }

            a {
              font-size: 1.4rem;
              font-weight: 500;
              text-align: center;
              color: #4A4A4A;
            }
          }
        }
      }

      &__lang {
        margin: auto 0 0 0;

        .lang {
          &__list {
            font-size: 0;
            text-align: center;
          }

          &__item {
            position: relative;
            display: inline-block;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(-50%, -50%);
              display: block;
              width: 1px;
              height: 27.91%;
              background: #707070;
            }

            &:last-child {
              &::after {
                display: none;
              }
            }

            a,
            span {
              display: block;
              padding: 8px 16px;
              font-size: 1.6rem;
              font-weight: 700;
            }

            span {
              position: relative;

              &::after {
                content: '';
                position: absolute;
                bottom: 8px;
                left: 50%;
                transform: translate(-50%, 0);
                display: block;
                width: 41.67%;
                height: 2px;
                background: #3B803B;
              }
            }
          }
        }
      }

      &__english {
        margin-top: 0;
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }
}