/* --------------------------------------------
   mod-primaryTtl_sp
----------------------------------------------- */

.mod-primaryTtl {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 0 17px 0;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.42;
  text-align: center;
  color: #4A4A4A;
  border-bottom: solid 4px #3B803B;
}