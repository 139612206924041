/* --------------------------------------------
   mod-toc_sp
----------------------------------------------- */

.mod-toc {
  margin: 0 0 64px 0;

  .toc {
    &__item {
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }

      a,
      span {
        position: relative;
        display: block;
        padding: 8px 62px 8px 20px;
        font-size: 1.6rem;
        font-weight: 700;
        text-align: center;
        border: solid 2px #3B803B;
        border-radius: 100px;
  
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(0, -50%) rotate(90deg);
          display: block;
          width: 9px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
  
      a {
        color: #3B803B;
        background: #FFFFFF;
  
        &::after {
          background-image: url(/assets/img/common/ico-arrow.svg);
        }
      }
  
      span {
        color: #FFFFFF;
        background: #3B803B;
  
        &::after {
          background-image: url(/assets/img/common/ico-arrow-white.svg);
        }
      }
    }
  }
}