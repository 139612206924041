// --------------------------------------------------------------------------------
//    reset
// --------------------------------------------------------------------------------

html, body, div, span,
article, section, nav, header, footer, main, aside,
h1, h2, h3, h4, h5, h6,
form, fieldset, legend, label,
dl, dt, dd, ol, ul, li, p,
table, caption, tbody, thead, tfoot, tr, th, td,
a, strong, em, mark, b, small, i, img,
object, figure, figcaption, canvas, audio, video, iframe {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0;
  box-sizing: border-box;
}
article, section, nav, header, footer, main, aside,
figure, figcaption {
  display: block;
}
html, body, div, p, span,
article, section, nav, header, footer, main, aside,
h1, h2, h3, h4, h5, h6,
input, select{
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input, button, textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
}
select::-ms-expand {
  display: none;
}
ol, ul {
  list-style: none;
}