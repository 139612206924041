/* --------------------------------------------
   news_pc
----------------------------------------------- */

#news {
  .main {
    padding: 106px 0 120px 0;
  }
  .news {
    &__articleList {
      margin: 0 0 64px 0;

      .articleList {
        &__item {
          margin: 0 0 16px 0;
        }

        &__desc {
          padding: 15px 130px 15px 15px;

          &::after {
            right: 30px;
            width: 6px;
            height: 10px;
          }
        }
      }
    }

    &__articleDetail {
      margin: 0 0 56px 0;
      padding: 32px 38px;

      .articleDetail {
        &__head {
          margin: 0 0 47px 0;
        }
  
        &__date {
          margin: 0 0 14px 0;
        }

        &__body {
          ul {
            li {
              padding: 0 0 0 21px;

              &::before {
                left: 5px;
              }
            }
          }
        }
      }
    }

    &__linearNav {
      margin: 0 0 35px 0;

      .linearNav {
        &__item {
          width: calc(50% - 28px);

          &--prev {
            a {
              &::before {
                left: 22px;
              }
            }
          }

          &--next {
            a {
              &::after {
                right: 22px;
              }
            }
          }
        }
      }

      &--articleDetail {
        .linearNav {
          &__item {
            max-width: 472px;
  
            &--prev {
              a {
                padding: 20px 23px 22px 53px;
              }
            }
  
            &--next {
              a {
                padding: 20px 53px 22px 23px;
              }
            }
          }

          &__term {
            font-size: 1.6rem;
          }

          &__date {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}