/* --------------------------------------------
   links_sp
----------------------------------------------- */

#links {
  .main {
    padding: 56px 0 105px 0;
  }

  .links {
    &__ttl {
      margin: 0 0 22px 0;
    }

    &__content {
      margin: 0 0 80px 0;

      &:last-child {
        margin: 0;
      }
    }

    &__horizontal {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: -4px;

      &--box {
        padding: 4px;
      }
    }

    &__list {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: -4px;
    }

    &__item {
      padding: 4px;

      a {
        font-size: 1.4rem;
        font-weight: 400;
        color: #4A4A4A;
      }
    }
  }
}