/* --------------------------------------------
   links_pc
----------------------------------------------- */

#links {
  .main {
    padding: 106px 0 120px 0;
  }

  .links {
    &__ttl {
      margin: 0 0 38px 0;
    }

    &__horizontal {
      flex-direction: row;
      margin: -4px -17px;

      &--box {
        padding: 4px 17px;
      }
    }

    &__list {
      margin: -4px -16px;

      &:only-child {
        flex-direction: row;
      }
    }

    &__item {
      padding: 4px 16px;
    }
  }
}

@media only screen and (min-width: $breakpoint-pc) {
  #links {
    .links {
      &__horizontal {
        margin: -4px -30px;
  
        &--box {
          padding: 4px 30px;
        }
      }
  
      &__list {
        margin: -4px -27px;
      }
  
      &__item {
        padding: 4px 27px;
      }
    }
  }
}