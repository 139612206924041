/* --------------------------------------------
   layout
----------------------------------------------- */

html {
  font-size: 62.5%;
}
body {
  @include font(14, 1.7, $textColor, 0, $normal);
  font-family: $font_notoSans;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a,
button {
  display: block;
  color: $textColor;
  text-decoration: none;
  transition: all $duration-fast ease-out 0s;
  cursor: pointer;

  &:hover {
    opacity: $defOpacity;
    transition: all $duration-fast ease-out 0s;
  }
}

img,
video {
  width: 100%;
  vertical-align: bottom;

  &[src$=".svg"] {
    width: auto;
    min-width: 100%;
    max-width: 100%;
  }
}

.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main {
  overflow: hidden;
  padding: 56px 0 0 0;
}
.only-sp {
  display: block;
}
.only-pc {
  display: none;
}
@media only screen and (min-width: $breakpoint) {
  .main {
    padding: 106px 0 0 0;
  }
  .only-sp {
    display: none;
  }
  .only-pc {
    display: block;
  }
}