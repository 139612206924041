/* --------------------------------------------
   mod-btn_sp
----------------------------------------------- */

.mod-btn {
  width: 100%;
  max-width: 327px;
  margin: 0 auto;

  a,
  button {
    width: 100%;
    height: 100%;
    padding: 14px;
    font: inherit;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    color: #3B803B;
    background: #FFFFFF;
    border: solid 4px #3B803B;
    border-radius: 100px;
  }

  &__small {
    max-width: 159px;

    a,
    button {
      padding: 4px;
      border-width: 2px;
    }
  }

  &__colorReverse {
    a,
    button {
      color: #FFFFFF;
      background: #3B803B;
      border-color: #FFFFFF;
    }
  }

  &__green {
    a,
    button {
      color: #FFFFFF;
      background: #3B803B;
      border-color: #3B803B;
    }
  }

  &__white {
    a,
    button {
      color: #3B803B;
      background: #FFFFFF;
      border-color: #FFFFFF;
    }
  }
}