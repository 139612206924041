/* --------------------------------------------
   privacy_pc
----------------------------------------------- */

#privacy {
  .main {
    padding: 106px 0 120px 0;
  }

  .privacy {

    .privacy-table {

      &__wrap {
        overflow-x: initial;
      }

      .table {
  
        &__head {
          height: 40px;
          line-height: 40px;
        }
        &__data {
          text-align: center;
        }

        &__dataTable .dataTable__data{
          padding:0px 16px;
          text-align: left;
        }
      }
      .privacy__link-list {
        max-width: 100%;
      }
    }
  }
}