/* --------------------------------------------
   english_pc
----------------------------------------------- */

#english {
  .main {
    padding: 106px 0 120px 0;
  }
  
  .english {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 32px auto;
          letter-spacing: 0;
        }

        &__txt {
          margin: 0 0 32px 0;
          font-weight: 500;
          line-height: 1.44;
          color: #333333;
        }
      }
    }
  }
}