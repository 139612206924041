/* --------------------------------------------
   mod-number_pc
----------------------------------------------- */

.mod-number {
  padding: 0 0 0 46px;

  .ico {
    top: calc(1.8rem * 1.44 / 2);
    left: 0;
    transform: translate(0, -50%);
  }

  .note {
    display: inline;
    margin: 0 0 0 21px;
  }

  &__center,
  &__left,
  &__righ {
    text-align: left;
  }
}