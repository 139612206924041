/* --------------------------------------------
   mod-link_sp
----------------------------------------------- */

.mod-link {
  width: 100%;
  max-width: 171px;
  margin: 0 auto;
  
  a,
  button {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 9px 15px 9px 0;
    font: inherit;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.29;
    color: #4A4A4A;
    border-bottom: solid 2px #3B803B;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      display: block;
      width: 7px;
      height: 11px;
      background-image: url(/assets/img/common/ico-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &__targetBlank {
    a,
    button {
      font-weight: 500;
      color: #3B803B;

      &::after {
        content: '';
        display: inline-block;
        width: 13px;
        height: 13px;
        margin: 0 0 0 5px;
        background-image: url(/assets/img/common/ico-targetBlank.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
}