/* --------------------------------------------
   catchphrase_sp
----------------------------------------------- */

#catchphrase {
  .main {
    padding: 56px 0 120px 0;
  }

  .catchphrase {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 24px auto;
          letter-spacing: -0.03em;
        }

        &__content {
          margin: 0 0 80px 0;

          &:last-child {
            margin: 0;
          }
        }

        &__txt {
          margin: 0 0 28px 0;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.5;
          color: #4A4A4A;

          &:last-child {
            margin: 0;
          }
        }

        &__point {
          margin: 0 0 25px 0;
          padding: 26px 25px;
          border: solid 2px #3B803B;
          border-radius: 18px;

          &:last-child {
            margin: 0;
          }

          .point {
            &__txt {
              margin: 0 0 23px 0;
              font-size: 1.6rem;
              font-weight: 500;
              line-height: 1.44;
              letter-spacing: 0.03em;
              color: #333333;

              &:last-child {
                margin: 0;
              }

              a {
                display: inline;
                font-weight: 700;
                text-decoration: underline;
              }

              .large {
                font-size: 1.8rem;
                letter-spacing: 0;
              }

              .bold {
                font-weight: 700;
              }

              .red {
                color: #E20000;
              }
            }

            &__term {
              margin: 0 0 27px 0;
            }

            &__flow {
              .flow {
                &__item {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  width: 100%;
                  height: 71px;
                  margin: 0 0 40px 0;
                  font-size: 1.6rem;
                  font-weight: 700;
                  line-height: 1.44;
                  text-align: center;
                  color: #4A4A4A;
                  border: solid 6px #DEA93D;

                  &::after {
                    content: '';
                    position: absolute;
                    top: calc(100% + 14px);
                    left: 50%;
                    transform: translate(-50%, 0);
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 9px 0 9px;
                    border-color: #DEA93D transparent transparent transparent;
                  }

                  &:last-child {
                    margin: 0;

                    &::after {
                      display: none;
                    }
                  }
                }
              }
            }

            &__doc {
              .doc {
                &__items {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: wrap;
                  margin: -12px;
                }

                &__item {
                  margin: 12px;

                  &--btn {
                    max-width: 300px;
                  }

                  &--link {
                    max-width: 140px;
                  }
                }
              }
            }
          }
        }

        &__example {
          margin: 0 0 69px 0;

          &:last-child {
            margin: 0;
          }

          .example {
            &__horizontal {
              display: flex;
              flex-wrap: wrap;
              margin: -11px;

              &--left,
              &--right {
                width: 100%;
                padding: 11px;
              }
            }

            &__term {
              margin: 0 0 13px 0;
              font-size: 2.4rem;
              font-weight: 700;
              color: #333333;
            }

            &__table {
              overflow-x: scroll;

              .table {
                &__header {
                  width: 74px;
                  min-width: 74px;
                  max-width: 74px;
                  padding: 2px 12px;
                  font-size: 1.6rem;
                  line-height: 1.6;
                }

                &__data {
                  width: 356px;
                  min-width: 356px;
                  max-width: 356px;
                  padding: 5px 16px;
                  font-size: 1.4rem;
                  line-height: 1.6;
                }
              }
            }
          }
        }

        &__logo {
          .logo {
            &__img {
              width: 100%;
              max-width: 334px;
              margin: 0 auto 64px auto;
            }

            &__desc {
              margin: 0 0 39px 0;
            }

            &__term {
              margin: 0 0 24px 0;
              font-size: 2.4rem;
              font-weight: 700;
              line-height: 1.46;
              text-align: center;
              color: #4A4A4A;
            }

            &__dfn {
              font-size: 1.6rem;
              font-weight: 500;
              line-height: 1.5;
              color: #333333;
            }

            &__attention {
              padding: 18px 21px;
              font-size: 1.6rem;
              font-weight: 700;
              line-height: 1.44;
              text-align: center;
              color: #E20000;
              border: solid 6px #F2DBDB;
            }
          }
        }

        &__table {
          overflow-x: scroll;

          .table {
            &__header {
              width: 64px;
              min-width: 64px;
              max-width: 64px;
              padding: 13px 15px;
              font-size: 1.8rem;
              line-height: 1.33;
            }

            &__data {
              width: 777px;
              min-width: 777px;
              max-width: 777px;
              font-size: 1.6rem;
              line-height: 1.5;

              .dataTable {
                &__header,
                &__data {
                  padding: 21px 28px;
                }

                &__txt {
                  .bold {
                    font-weight: 700;
                  }
                }

                &__term {
                  margin: 0 0 15px 0;
                  font-size: 1.8rem;
                  font-weight: 700;

                  &::before {
                    content: '▼';
                    margin: 0 0 0 3px;
                  }
                }

                &__list {
                  counter-reset: dataTable;
                }

                &__item {
                  position: relative;
                  padding: 0 0 0 21px;

                  &::before {
                    counter-increment: dataTable;
                    content: '['counter(dataTable)']';
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

.news {
    padding: 75px 0 80px 0;

    &__ttl {
      margin: 0 0 29px 0;
      font-size: 2.4rem;
      font-weight: 700;
      text-align: center;
      color: #333333;
    }

    &__list {
      margin: 0 0 48px 0;
    }

    &__item {
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }

    &__desc {
      position: relative;
      padding: 14px 52px 16px 15px;
      border: solid 1px #D5D5D5;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translate(0, -50%);
        display: block;
        width: 6px;
        height: 10px;
        background-image: url(/assets/img/common/ico-arrow-black.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &__term {
      margin: 0 0 5px 0;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.44;
      color: #4A4A4A;
    }

    &__date {
      margin: 0 0 14px 0;
      font-size: 1.2rem;
      font-weight: 400;
      color: #4A4A4A;
    }

    &__category {
      .category {
        &__items {
          margin: -2px;
          font-size: 0;
        }

        &__item {
          display: inline-block;
          margin: 2px;
          padding: 1px 12px;
          font-size: 1rem;
          font-weight: 500;
          color: #4A4A4A;
          border: solid 2px #000;
          border-radius: 100px;

          &--01 {
            border-color: #3B803B;
          }

          &--02 {
            border-color: #F09400;
          }

          &--03 {
            border-color: #0B73DB;
          }
        }
      }
    }
  }
}
