/* --------------------------------------------
   mod-secondaryTtl_sp
----------------------------------------------- */

.mod-secondaryTtl {
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  padding: 5px;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.33;
  text-align: center;
  color: #FFFFFF;
  background: #3B803B;
  border-radius: 100px;

  &--large {
    max-width: 527px;
  }
}