/* --------------------------------------------
   contact_pc
----------------------------------------------- */

#contact {
  .main {
    padding: 106px 0 120px 0;
  }

  // お問い合わせフォーム
  .contact {

    &__attention {
      margin: 33px 0 0;
    }

    &__wrap {
    margin: 40px 0 48px;
    }

    &__inner {
      padding: 56px 0;
    }

    //　問い合わせ項目
    &-item {
      display: flex;
      margin-bottom: 24px;

      &__ttl {
        width:196px;
      }

      &__data {

        &.is-error {
          .mod-form__input-txt {
            border: 1px solid #E20000;
          }

          .mod-form__textarea {
            border: 1px solid #E20000;
          }
        }
      }

    }

    // ボタン
    &-btn {
      margin: 0 8px;

      &__wrap {
        display: flex;
        justify-content: center;
      }

      &--primary {
        button {
          background:#3B803B;
          color:#fff;
        }
      }

    }

    // 送信完了画面
    &-end {
      &__lead {
        text-align: center;
      }

      &__wrap {
        padding: 24px 0;
        margin: 88px 0 80px;
      }

      &__tel {

        .only-pc {
          display: inline;
        }
      }

    }
  }

  // 個人情報取扱同意書
  .agreement {

    &__inner {
      margin: 30px auto 0;
    }
  }

  // 同意する
  .agree {
    text-align: center;
    justify-content: center;
    margin-bottom: 50px;

    &__txt {
      margin-left: 30px;
    }
  }
}