/* --------------------------------------------
   mod-table_sp
----------------------------------------------- */

.mod-table {
  width: 100%;
  height: 100%;

  .table {
    &__header,
    &__data {
      color: #4A4A4A;
      border: solid 1px #707070;
      vertical-align: middle;
    }

    &__header {
      font-weight: 700;
      background: #F0F0F0;
    }

    &__data {
      font-weight: 500;
      background: #FFFFFF;

      .dataTable {
        width: calc(100% + 1px * 2);
        height: calc(100% + 1px * 2);
        margin: -1px;
        
        &__header,
        &__data {
          border: solid 1px #707070;
          vertical-align: middle;
        }
      }
    }
  }
}