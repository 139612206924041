/* --------------------------------------------
   brand_pc
----------------------------------------------- */

#brand {
  .main {
    padding: 106px 0 120px 0;
  }

  .brand {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 32px auto;
          letter-spacing: 0;
        }

        &__point {
          margin: 0 0 24px 0;
          padding: 30px 32px;

          .point {
            &__ttl {
              margin: 0 auto 11px auto;
            }

            &__horizontal {
              &--chart {
                margin: -24px;

                .point {
                  &__horizontal {
                    &--left,
                    &--right {
                      padding: 24px;
                    }

                    &--left {
                      order: 2;
                      width: 39.59%;
                    }

                    &--right {
                      order: 1;
                      width: calc(100% - 39.59%);
                    }
                  }
                }
              }

              &--period {
                margin: -17px;

                .point {
                  &__horizontal {
                    &--left,
                    &--right {
                      padding: 17px;
                    }

                    &--left {
                      width: 71.64%;
                    }

                    &--right {
                      align-self: flex-end;
                      width: calc(100% - 71.64%);
                    }
                  }
                }
              }
            }

            &__img {
              &--mind {
                max-width: 1000px;
              }
            }

            &__unit {
              margin: 0 0 17px 0;
            }

            &__term {
              margin: 0 0 17px 0;
            }

            &__item {
              margin: 0 0 19px 0;
            }

            &__doc {
              .doc {
                &__item {
                  margin: 0 auto 7px auto;
                }
              }
            }
          }
        }

        &__mark {
          .mark {
            &__content {
              &::after {
                content: '';
                display: block;
                clear: both;
              }
            }

            &__img {
              float: left;
              margin: 0 25px 25px 0;
            }
          }
        }
      }
    }
  }
}