/* --------------------------------------------
   catchphrase_pc
----------------------------------------------- */

#catchphrase {
  .main {
    padding: 106px 0 120px 0;
  }

  .catchphrase {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 32px auto;
          letter-spacing: 0;
        }

        &__txt {
          margin: 0 0 32px 0;
          font-weight: 500;
          line-height: 1.44;
          color: #333333;
        }

        &__point {
          margin: 0 0 33px 0;

          .point {
            &__txt {
              letter-spacing: 0;
            }

            &__term {
              margin: 0 0 25px 0;
            }

            &__flow {
              .flow {
                &__items {
                  margin: -16px;
                  font-size: 0;
                  text-align: center;
                }

                &__item {
                  display: inline-flex;
                  vertical-align: middle;
                  width: 125px;
                  height: 125px;
                  margin: 16px;

                  &::after {
                    top: 50%;
                    left: calc(100% + 16px);
                    transform: translate(0, -50%);
                    border-width: 9px 0 9px 12px;
                    border-color: transparent transparent transparent #DEA93D;
                  }

                  &:last-child {
                    margin: 16px;
                  }
                }
              }
            }

            &__doc {
              .doc {
                &__items {
                  margin: -10px;
                }

                &__item {
                  margin: 10px;

                  &--btn {
                    max-width: 191px;

                    a,
                    button {
                      padding: 4px;
                      border-width: 2px;
                    }
                  }
                }
              }
            }
          }
        }

        &__example {
          margin: 0 0 35px 0;

          .example {
            &__horizontal {
              margin: -20px;

              &--left,
              &--right {
                padding: 20px;
              }

              &--left {
                width: 40.39%;
              }

              &--right {
                width: calc(100% - 40.39%);
              }
            }

            &__table {
              overflow-x: visible;

              .table {
                &__header {
                  width: 120px;
                  min-width: auto;
                  max-width: none;
                }

                &__data {
                  width: 460px;
                  min-width: auto;
                  max-width: none;
                }
              }
            }
          }
        }

        &__logo {
          .logo {
            &__img {
              margin: 0 auto 111px auto;
            }

            &__desc {
              margin: 0 0 32px 0;
            }

            &__dfn {
              line-height: 1.44;
            }

            &__attention {
              padding: 9px;
            }
          }
        }

        &__table {
          overflow-x: visible;

          .table {
            &__header {
              width: 224px;
              min-width: auto;
              max-width: none;
              line-height: 1.78;
            }

            &__data {
              width: 777px;
              min-width: auto;
              max-width: none;
            }
          }
        }
      }
    }
  }

  .news {
    padding: 77px 0 80px 0;

    &__ttl {
      margin: 0 0 38px 0;
    }

    &__desc {
      padding: 14px 130px 16px 15px;

      &::after {
        right: 30px;
      }
    }

    &__term {
      color: #333333;
    }

    &__date {
      color: #333333;
    }
  }
}
