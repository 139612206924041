/* --------------------------------------------
   mod-number_sp
----------------------------------------------- */

.mod-number {
  position: relative;
  padding: 50px 0 0 0;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.44;
  color: #333333;

  .ico {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 32px;
    height: 32px;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 0;
    color: #FFFFFF;
    background: #3B803B;
    border-radius: 100%;
  }

  .note {
    display: block;
    margin: 9px 0 0 0;
    font-size: 1.2rem;
  }

  &__center {
    text-align: center;
  }

  &__left {
    text-align: left;
  }

  &__right {
    text-align: right;
  }
}