/* --------------------------------------------
   privacy_sp
----------------------------------------------- */

#privacy {
  .main {
    padding: 56px 0 106px 0;
  }

  .privacy {

    &__lead {
      font-size: 1.6rem;
      font-weight:bold;
      margin-bottom: 80px;
    }

    &__section {
      margin-bottom: 48px;
    }

    &__main-ttl {
      font-size: 1.8rem;
      font-weight:bold;
      line-height: 1.77;
      margin-bottom: 8px;
    }

    &__sub-ttl {
      font-size: 1.8rem;
      font-weight:bold;
      line-height: 1.77;
      margin: 40px 0 8px;
    }

    &__content {
      margin-top: 80px;
    }

    &__text {
      font-size: 1.4rem;
      line-height: 2.2;
      margin-bottom: 12px;
    }

    &__link {

      &-list {
        max-width: 610px;

        &--cookie {
          margin: 32px 0;
          .privacy__link-item {
            padding-top: 12px;
            a {
              padding: 4px 14px 20px 0;
            }
          } 
        }
      }
      &-item {
        border-bottom: 2px solid #3B803B;

        &-multi {
          padding-top: 9px;
          color: #3B803B;
        }

        a {
          color:#3B803B;
          font-weight:normal;
          position: relative;
          padding: 9px 14px 9px 0;
          font-size: 1.4rem;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(0, -50%);
            display: block;
            width: 4px;
            height: 9px;
            background-image: url(/assets/img/common/ico-arrow.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }

        span {
          position: relative;
          &::after {
            display:inline-block;
            top: 0;
            bottom: 0;
            margin-left: 10px;
            background-image: url(/assets/img/common/ico-targetBlank.svg);
            background-size: contain;
            width: 12px;
            height: 12px;
            content: '';
          }
        }
      }

    }

    .privacy-table {
      margin: 40px 0 80px;

      &__wrap {
        overflow-x:auto;
      }

      .table {
        &__head {
          height: 40px;
          line-height: 40px;
        }
        &__header {
          min-width: 90px;
        }
        &__data {
          min-width: 90px;
          text-align: center;
        }
        &__dataTable .dataTable__data{
          padding:0px 16px;
          text-align: left;
        }
      }

      .privacy__link-list {
        max-width: 240px;
      }
    }
  }
}