/* --------------------------------------------
   news_sp
----------------------------------------------- */

#news {
  .main {
    padding: 56px 0 106px 0;
  }
  .news {
    &__articleList {
      margin: 0 0 57px 0;

      .articleList {
        &__item {
          margin: 0 0 17px 0;

          &:last-child {
            margin: 0;
          }
        }

        &__desc {
          position: relative;
          padding: 15px 31px 15px 15px;
          border: solid 1px #D5D5D5;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translate(0, -50%);
            display: block;
            width: 7px;
            height: 11px;
            background-image: url(/assets/img/common/ico-arrow-black.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }

        &__term {
          margin: 0 0 5px 0;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.44;
          color: #4A4A4A;
        }

        &__date {
          margin: 0 0 14px 0;
          font-size: 1.2rem;
          font-weight: 400;
          color: #4A4A4A;
        }

        &__category {
          .category {
            &__items {
              margin: -2px;
              font-size: 0;
            }

            &__item {
              display: inline-block;
              margin: 2px;
              padding: 1px 12px;
              font-size: 1rem;
              font-weight: 500;
              color: #4A4A4A;
              border: solid 2px #000;
              border-radius: 100px;

              &--01 {
                border-color: #3B803B;
              }

              &--02 {
                border-color: #F09400;
              }

              &--03 {
                border-color: #0B73DB;
              }
            }
          }
        }
      }
    }

    &__articleDetail {
      margin: 0 -24px 56px -24px;
      padding: 32px 24px;
      border: solid 24px #F4F4F4;

      .articleDetail {
        &__ttl {
          margin: 0 0 14px 0;
          font-size: 2.4rem;
          font-weight: 500;
          line-height: 1.46;
          text-align: center;
          color: #4A4A4A;
        }

        &__head {
          margin: 0 0 32px 0;
        }

        &__date {
          margin: 0 0 22px 0;
          font-size: 1.4rem;
          font-weight: 400;
          text-align: center;
          color: #4A4A4A;
        }

        &__category {
          .category {
            &__items {
              margin: -2px;
              font-size: 0;
              text-align: center;
            }

            &__item {
              display: inline-block;
              margin: 2px;
              padding: 1px 12px;
              font-size: 1rem;
              font-weight: 500;
              color: #4A4A4A;
              border: solid 2px #000;
              border-radius: 100px;

              &--01 {
                border-color: #3B803B;
              }

              &--02 {
                border-color: #F09400;
              }

              &--03 {
                border-color: #0B73DB;
              }
            }
          }
        }

        &__body {
          h3 {
            font-size: 2.2rem;
            font-weight: 700;
            line-height: 1.5;
            color: #3B803B;
          }

          h4 {
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 1.5;
            color: #4A4A4A;
          }

          p {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2;
            color: #4A4A4A;
          }

          a {
            display: inline-block;
            position: relative;
            padding: 9px 15px 9px 0;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.29;
            color: #4A4A4A;
            border-bottom: solid 2px #3B803B;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -50%);
              display: block;
              width: 7px;
              height: 11px;
              background-image: url(/assets/img/common/ico-arrow.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }
          }

          ul {
            li {
              position: relative;
              margin: 0 0 -1px 0;
              padding: 0 0 0 16px;
              font-size: 1.6rem;
              font-weight: 400;
              line-height: 2;
              color: #4A4A4A;

              &::before {
                content: '';
                position: absolute;
                top: calc(1.6rem * 2 / 2);
                left: 0;
                transform: translate(0, -50%);
                display: block;
                width: 8px;
                height: 8px;
                background: #3B803B;
                border-radius: 100%;
              }

              &:last-child {
                margin: 0;
              }
            }
          }

          ol {
            counter-reset: articleDetail;

            li {
              position: relative;
              margin: 0 0 7px 0;
              padding: 0 0 0 26px;
              font-size: 1.6rem;
              font-weight: 400;
              line-height: 2;
              color: #4A4A4A;

              &::before {
                counter-increment: articleDetail;
                content: counter(articleDetail);
                position: absolute;
                top: calc(1.6rem * 2 / 2);
                left: 0;
                transform: translate(0, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 18px;
                height: 18px;
                font-size: 1.1rem;
                font-weight: 700;
                line-height: 0;
                color: #FFFFFF;
                background: #3B803B;
                border-radius: 100%;
              }

              &:last-child {
                margin: 0;
              }
            }
          }

          table {
            overflow-x: auto;
            display: block;

            td {
              padding: 7px 19px;
              font-size: 1.4rem;
              font-weight: 500;
              line-height: 1.57;
              white-space: nowrap;
              color: #4A4A4A;
              background: #FFFFFF;
              border: solid 1px #707070;
              vertical-align: middle;
            }
          }
        }
      }
    }

    &__linearNav {
      margin: 0 0 40px 0;

      .linearNav {
        &__items {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__item {
          width: calc(50% - 12px);

          a {
            position: relative;
            border: solid 1px #D5D5D5;

            &::before,
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              display: block;
              width: 8px;
              height: 14px;
              background-image: url(/assets/img/common/ico-arrow-black.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }
          }

          &--prev {
            margin: 0 auto 0 0;

            a {
              &::before {
                left: 14px;
                transform: translate(0, -50%) rotate(180deg);
              }

              &::after {
                display: none;
              }
            }
          }

          &--next {
            margin: 0 0 0 auto;

            a {
              &::before {
                display: none;
              }

              &::after {
                right: 14px;
                transform: translate(0, -50%) rotate(0);
              }
            }
          }
        }
      }

      &--articleList {
        .linearNav {
          &__item {
            max-width: 151px;

            a {
              font-size: 1.4rem;
              font-weight: 500;
              text-align: center;
              color: #4A4A4A;
            }

            &--prev {
              a {
                padding: 19px 15px 19px 37px;
              }
            }

            &--next {
              a {
                padding: 19px 37px 19px 15px;
              }
            }
          }
        }
      }

      &--articleDetail {
        .linearNav {
          &__item {
            max-width: 151px;

            &--prev {
              a {
                padding: 15px 15px 16px 37px;
              }
            }

            &--next {
              a {
                padding: 15px 37px 16px 15px;
              }
            }
          }

          &__desc {
            display: flex;
            flex-direction: column;
          }

          &__term {
            order: 2;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.29;
            color: #4A4A4A;
          }

          &__dfn {
            order: 1;
            margin: 0 0 7px 0;
          }

          &__date {
            font-size: 1.2rem;
            font-weight: 400;
            color: #4A4A4A;
          }
        }
      }
    }
  }
}