/* --------------------------------------------
   mod-breadcrumb_sp
----------------------------------------------- */

.mod-breadcrumb {
  margin: 0 0 79px 0;

  .breadcrumb {
    &__list {
      margin: -3px -6px;
      font-size: 0;
      text-align: right;
    }

    &__item {
      display: inline-block;
      margin: 3px 6px;
  
      &::after {
        content: '>';
        margin: 0 0 0 14px;
        font-size: 1.4rem;
        font-weight: 500;
        color: #333333;
      }
      
      &:last-child {
        &::after {
          display: none;
        }
      }
  
      a,
      span {
        display: inline;
        font-size: 1.4rem;
      }

      a {
        font-weight: 700;
        color: #3B803B;
      }

      span {
        font-weight: 500;
        color: #333333;
      }
    }
  }
}