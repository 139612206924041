/* --------------------------------------------
   brand_sp
----------------------------------------------- */

#brand {
  .main {
    padding: 56px 0 120px 0;
  }

  .brand {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 24px auto;
          letter-spacing: -0.03em;
        }

        &__content {
          margin: 0 0 80px 0;

          &:last-child {
            margin: 0;
          }
        }

        &__txt {
          margin: 0 0 14px 0;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.71;
          color: #4A4A4A;

          &:last-child {
            margin: 0;
          }

          .red {
            color: #FF0000;
          }
        }

        &__point {
          margin: 0 0 26px 0;
          padding: 23px 22px;
          border: solid 2px #3B803B;
          border-radius: 18px;

          &:last-child {
            margin: 0;
          }

          .point {
            &__ttl {
              margin: 0 auto 16px auto;
            }

            &__horizontal {
              display: flex;
              flex-wrap: wrap;

              &--chart {
                margin: -16px;

                .point {
                  &__horizontal {
                    &--left,
                    &--right {
                      width: 100%;
                      padding: 16px;
                    }
                  }
                }
              }

              &--period {
                margin: -12px;

                .point {
                  &__horizontal {
                    &--left,
                    &--right {
                      width: 100%;
                      padding: 12px;
                    }
                  }
                }
              }
            }

            &__txt {
              margin: 0 0 23px 0;
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1.71;
              letter-spacing: -0.02em;
              color: #4A4A4A;

              &:last-child {
                margin: 0;
              }

              .red {
                color: #FF0000;
              }
            }

            &__img {
              width: 100%;
              max-width: 1000px;
              margin: 0 auto;

              &--process {
                max-width: 570px;
              }

              &--mind {
                max-width: 240px;
              }
            }

            &__unit {
              margin: 0 0 30px 0;

              &:last-child {
                margin: 0;
              }
            }

            &__term {
              margin: 0 0 15px 0;
            }

            &__item {
              margin: 0 0 32px 0;

              &:last-child {
                margin: 0;
              }
            }

            &__doc {
              .doc {
                &__item {
                  margin: 0 auto 10px auto;

                  &:last-child {
                    margin: 0 auto;
                  }

                  &--link {
                    max-width: 1000px;
                  }
                }
              }
            }
          }
        }

        &__mark {
          .mark {
            &__ttl {
              margin: 0 auto 29px auto;
            }

            &__img {
              width: 100%;
              max-width: 225px;
              margin: 0 auto 25px auto;
            }

            &__txt {
              margin: 0 0 24px 0;
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1.71;
              color: #4A4A4A;

              &:last-child {
                margin: 0;
              }
            }

            &__attention {
              margin: 28px 0 0 0;
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1.71;
              text-align: right;
              color: #FF0000;
            }
          }
        }
      }
    }
  }
}