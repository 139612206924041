/* --------------------------------------------
   footer_pc
----------------------------------------------- */

.footer {
  &__primaryMenu {
    padding: 77px 0 45px 0;
    
    .primaryMenu {
      &__inner {
        position: relative;
        padding: 0 129px 0 0;
      }

      &__list {
        margin: -12px -24px;
        font-size: 0;
      }

      &__item {
        display: inline-block;
        margin: 12px 24px;

        &:last-child {
          margin: 12px 24px;
        }

        a {
          font-size: 1.6rem;
          font-weight: 700;
        }
      }

      &__backToTop {
        position: absolute;
        top: 3px;
        right: 0;
      }
    }
  }

  &__secondaryMenu {
    padding: 46px 0 45px 0;

    .secondaryMenu {
      &__desc {
        margin: -40px;
        padding: 0 127px 0 0;

        &::after {
          bottom: 40px;
          left: auto;
          right: 40px;
        }
      }

      &__unit {
        width: auto;
        max-width: 33.33%;
        padding: 40px;
      }

      &__term {
        margin: 0 0 19px 0;
      }

      &__item {
        margin: 0 0 8px 0;
      }
    }
  }

  &__access {
    padding: 46px 0 45px 0;

    .access {
      &__unit {
        margin: 0 28px 0 0;

        &--address {
          margin: 0 0 7px 0;

          .access {
            &__term {
              margin: 0 0 7px 0;
            }
          }
        }
      }

      &__term,
      &__dfn {
        font-size: 1.4rem;
        color: #4A4A4A;
      }
    }
  }

  &__copyright {
    padding: 39px 0;

    .copyright {
      &__txt {
        font-size: 1.4rem;
        text-align: right;
      }
    }
  }
}