/* --------------------------------------------
   product_sp
----------------------------------------------- */

#product {
  .main {
    padding: 56px 0 109px 0;
  }

  .product {
    &__articleSearch {
      .articleSearch {
        &__ttl {
          margin: 0 auto 32px auto;
        }

        &__type {
          margin: 0 0 80px 0;

          .type {
            &__ttl {
              margin: 0 auto 32px auto;

              &::after {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                margin: 0 0 0 9px;
                background-image: url(/assets/img/common/ico-search-white.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }
            }

            &__list {
              display: flex;
              flex-wrap: wrap;
              margin: -11px -0.5px calc(-11px + 38px) -0.5px;
            }

            &__item {
              width: 50%;
              padding: 11px 0.5px;
            }

            &__desc {
              display: flex;
              flex-direction: column;
            }

            &__term {
              order: 2;
              font-size: 1.4rem;
              font-weight: 700;
              text-align: center;
              color: #333333;
            }

            &__dfn {
              order: 1;
              min-height: 0%;
              margin: 0 0 14px 0;
            }
          }
        }

        &__area {
          margin: 0 0 80px 0;

          .area {
            &__ttl {
              margin: 0 auto 30px auto;

              &::after {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                margin: 0 0 0 9px;
                background-image: url(/assets/img/common/ico-search-white.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }
            }

            &__list {
              margin: 0 0 38px 0;

              &::after {
                content: '';
                display: block;
                clear: both;
              }
            }

            &__item {
              margin: 0 0 18px 0;

              &--last {
                margin: 0;
              }

              &--left {
                float: left;
                clear: both;
                width: calc(50% - 20px);
              }

              &--right {
                padding: 0 0 0 calc(100% - (50% - 20px));
              }

              label {
                position: relative;
                display: block;
                padding: 0 0 0 40px;
                font-size: 1.6rem;
                font-weight: 500;
                color: #333333;
                
                .ico {
                  position: absolute;
                  top: calc(1.7em / 2);
                  left: 0;
                  transform: translate(0, -50%);
                  display: block;
                  width: 24px;
                  height: 24px;
                  text-indent: -9999px;
                  border: solid 1px #959EA7;
                  border-radius: 2px;

                  &::before {
                    content: '';
                    position: absolute;
                    top: 44%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(315deg);
                    display: block;
                    width: 8px;
                    height: 5px;
                    border-bottom: solid 3px #3B4043;
                    border-left: solid 3px #3B4043;
                    opacity: 0;
                  }
                }
              }
              
              input[type="checkbox"] {
                display: none;

                &:checked {
                  &+label {
                    .ico {
                      &::before {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &__keyword {
          .keyword {
            &__ttl {
              margin: 0 auto 32px auto;

              &::after {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                margin: 0 0 0 9px;
                background-image: url(/assets/img/common/ico-search-white.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }
            }

            &__input {
              position: relative;
              width: 100%;
              max-width: 300px;
              margin: 0 auto;

              input[type="text"] {
                display: block;
                width: 100%;
                height: 100%;
                padding: 15px 60px 15px 15px;
                background: #FFFFFF;
                border: solid 1px #BEBEBE;
                border-radius: 100px;
              }

              input[type="submit"] {
                position: absolute;
                top: 50%;
                right: 21px;
                transform: translate(0, -50%);
                display: block;
                width: 24px;
                height: 24px;
                text-indent: -9999px;
                background-image: url(/assets/img/common/ico-search-black.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    &__articleList {
      margin: 0 0 96px 0;
      
      .articleList {
        &__ttl {
          margin: 0 auto 23px auto;
        }

        &__head {
          margin: 0 0 38px 0;
        }

        &__result {
          margin: 0 0 4px 0;
          font-size: 1.4rem;
          font-weight: 500;
          text-align: center;
          color: #333333;
        }

        &__sortOrder {
          .sortOrder {
            &__desc {
              font-size: 0;
              text-align: center;
            }

            &__term {
              display: inline-block;
              font-size: 1.4rem;
              font-weight: 500;
              color: #333333;

              &::after {
                content: '：';
              }
            }

            &__dfn {
              display: inline-block;
            }

            &__list {
              margin: -7px -14px;
              font-size: 0;
              text-align: center;
            }

            &__item {
              display: inline-block;
              margin: 7px 14px;

              a,
              span {
                font-size: 1.4rem;
                font-weight: 500;
              }

              a {
                text-decoration: underline;
                color: #0078C3;
              }

              span {
                color: #333333;
              }
            }
          }
        }

        &__banner {
          width: 100%;
          max-width: 300px;
          margin: 0 auto 38px auto;
        }

        &__item {
          margin: 0 0 68px 0;
          
          &:last-child {
            margin: 0;
          }
        }

        &__horizontal {
          display: flex;
          flex-wrap: wrap;
          margin: -12px;

          &--left,
          &--right {
            width: 100%;
            padding: 12px;
          }
        }

        &__desc {
          margin: 0 0 23px 0;
        }

        &__term {
          margin: 0 0 16px 0;
          font-size: 2.4rem;
          font-weight: 700;
          line-height: 1.46;
          color: #333333;

          span {
            display: block;
            font-size: 1.8rem;
          }
        }

        &__txt {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.5;
          color: #4A4A4A;

          &:last-child {
            margin: 0;
          }
        }

        &__btn {
          .btn {
            &__items {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-wrap: wrap;
              margin: -4px;
            }

            &__item {
              margin: 4px;
            }
          }
        }
      }
    }

    &__articleDetail {
      margin: 0 0 120px 0;

      .articleDetail {
        &__ttl {
          margin: 0 auto 24px auto;
        }

        &__txt {
          margin: 0 0 23px 0;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.5;
        }

        &__img {
          margin: 0 0 40px 0;
        }

        &__feature {
          margin: 0 0 71px 0;

          &:last-child {
            margin: 0;
          }

          .feature {
            &__ttl {
              margin: 0 auto 21px auto;
            }

            &__horizontal {
              display: flex;
              flex-wrap: wrap;
              margin: -12px;

              &--left,
              &--right {
                width: 100%;
                padding: 12px;
              }
            }

            &__txt {
              margin: 0 0 24px 0;
              font-size: 1.6rem;
              font-weight: 400;
              line-height: 1.5;
              color: #4A4A4A;

              &:last-child {
                margin: 0;
              }
            }
          }
        }

        &__table {
          .table {
            &__header {
              display: block;
              width: 100%;
              padding: 2px 33px;
              font-size: 1.6rem;
              line-height: 1.6;
              color: #FFFFFF;
              background: #3B803B;
              border: solid 1px #3B803B;
            }

            &__data {
              display: block;
              width: 100%;
              padding: 4px 33px;
              font-size: 1.4rem;
              line-height: 1.6;
              text-align: center;

              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &__pagination {
      margin: 0 0 80px 0;

      .pagination {
        &__items {
          margin: -8px;
          font-size: 0;
          text-align: center;
        }

        &__item {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin: 8px;

          a,
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            font-size: 1.8rem;
            font-weight: 700;
          }

          a {
            color: #3B803B;
            background: #FFFFFF;
            border: solid 1px #3B803B;
          }

          span {
            color: #FFFFFF;
            background: #BABABA;
          }

          &--prev,
          &--next {
            a {
              position: relative;
              text-indent: -9999px;
              background: #3B803B;

              &::before,
              &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 6px;
                height: 10px;
                background-image: url(/assets/img/common/ico-arrow-white.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
              }
            }
          }

          &--prev {
            a {
              &::before {
                transform: translate(-50%, -50%) rotate(180deg);
              }

              &::after {
                display: none;
              }
            }
          }

          &--next {
            a {
              &::before {
                display: none;
              }

              &::after {
                transform: translate(-50%, -50%) rotate(0);
              }
            }
          }
        }
      }
    }

    &__btn {
      .btn {
        &__items {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin: -16px;
        }

        &__item {
          margin: 16px;
        }
      }
    }
  }
}