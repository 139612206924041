/* --------------------------------------------
   mod-backToTop_sp
----------------------------------------------- */

.mod-backToTop {
  width: 81px;
  height: 81px;

  a,
  button {
    position: relative;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    background: #3B803B;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(270deg);
      display: block;
      width: 13px;
      height: 21px;
      background-image: url(/assets/img/common/ico-arrow-white.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}