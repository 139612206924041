/* --------------------------------------------
   mod-toc_pc
----------------------------------------------- */

.mod-toc {
  margin: 0 0 71px 0;

  .toc {
    &__list {
      margin: -12px;
      font-size: 0;
      text-align: center;
    }

    &__item {
      display: inline-block;
      margin: 12px;

      &:last-child {
        margin: 12px;
      }

      a,
      span {
        min-width: 190px;
        padding: 8px 58px 8px 24px;
      }
    }
  }
}