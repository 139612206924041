/* --------------------------------------------
   mod-heading_pc
----------------------------------------------- */

.mod-heading {
  height: 193px;
  margin: 0 0 18px 0;

  .heading {
    &__ttl {
      font-size: 3.2rem;
    }
  }
}