/* --------------------------------------------
   mod-btn_pc
----------------------------------------------- */

.mod-btn {
  max-width: 300px;

  &__small {
    max-width: 159px;
  }
}