/* --------------------------------------------
   catchphrase_sp
----------------------------------------------- */

#english {
  .main {
    padding: 56px 0 120px 0;
  }
  
  .english {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 24px auto;
          letter-spacing: -0.03em;
        }

        &__content {
          margin: 0 0 80px 0;

          &:last-child {
            margin: 0;
          }
        }

        &__txt {
          margin: 0 0 28px 0;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.5;
          color: #4A4A4A;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}