/* --------------------------------------------
   footer_sp
----------------------------------------------- */

.footer {
  overflow: hidden;
  border-top: solid 1px #E3E3E3;

  &__primaryMenu {
    padding: 77px 0 65px 0;
    border-bottom: solid 1px #E3E3E3;
    
    .primaryMenu {
      &__term {
        margin: 0 0 19px 0;
        font-size: 2.4rem;
        font-weight: 700;
        color: #000000;
      }

      &__item {
        margin: 0 0 8px 0;

        &:last-child {
          margin: 0;
        }

        a {
          font-size: 1.4rem;
          font-weight: 500;
          color: #4A4A4A;
        }
      }
    }
  }

  &__secondaryMenu {
    padding: 38px 0 48px 0;
    border-bottom: solid 1px #E3E3E3;

    .secondaryMenu {
      &__inner {
        position: relative;
      }

      &__desc {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: -22px;
        padding: 0 0 207px 0;

        &::after {
          content: '';
          position: absolute;
          bottom: 22px;
          left: 22px;
          display: block;
          width: 127px;
          height: 161px;
          background-image: url(/assets/img/common/img-mican.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      &__unit {
        width: 100%;
        padding: 22px;
      }

      &__term {
        margin: 0 0 11px 0;
        font-size: 1.8rem;
        font-weight: 700;
        color: #4A4A4A;
      }

      &__item {
        margin: 0 0 6px 0;

        &:last-child {
          margin: 0;
        }

        a {
          font-size: 1.4rem;
          font-weight: 400;
          color: #4A4A4A;
        }
      }

      &__backToTop {
        position: absolute;
        bottom: -2px;
        right: 0;
      }
    }
  }

  &__access {
    padding: 47px 0;

    .access {
      &__desc {
        &::after {
          content: '';
          display: block;
          clear: both;
        }
      }

      &__unit {
        float: left;
        margin: 0 24px 0 0;

        &--address {
          margin: 0 0 1px 0;

          .access {
            &__term {
              margin: 0 0 1px 0;
            }

            &__dfn {
              line-height: 1.83;
            }
          }
        }

        &--tel,
        &--fax {
          .access {
            &__term,
            &__dfn {
              display: inline;
            }

            &__term {
              &::after {
                content: '：';
              }
            }
          }
        }

        &--tel {
          clear: both;
        }
      }

      &__term,
      &__dfn {
        font-size: 1.2rem;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  &__copyright {
    padding: 32px 0 31px 0;
    background: #3B803B;

    .copyright {
      &__txt {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}