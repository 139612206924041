/* --------------------------------------------
   header_pc
----------------------------------------------- */

.header {
  height: 106px;

  &__logo {
    a {
      span {
        font-size: 2.4rem;
        color: #333333;
      }
    }
  }

  &__lang {
    margin: 0 0 0 16px;

    .lang {
      &__list {
        font-size: 0;
        text-align: center;
      }

      &__item {
        position: relative;
        display: inline-block;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
          display: block;
          width: 1px;
          height: 27.91%;
          background: #707070;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        a,
        span {
          display: block;
          padding: 8px 16px;
          font-size: 1.6rem;
          font-weight: 700;
        }

        span {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translate(-50%, 0);
            display: block;
            width: 41.67%;
            height: 2px;
            background: #3B803B;
          }
        }
      }
    }
  }

  &__nav {
    .nav {
      &__btn {
        width: 40px;
        height: 26px;
      }

      &__content {
        top: 106px;
        height: calc(100% - 106px);
        background-size: auto 83.57%;
      }

      &__inner {
        min-height: calc(100vh - 106px);
        padding: 118px 0;
      }
    }
  }
}