/* --------------------------------------------
   news_sp
----------------------------------------------- */

#contact {
  .main {
    padding: 56px 0 106px 0;
  }

  // お問い合わせフォーム
  .contact {

    &__attention {
      font-size: 1.6rem;
      color:#E20000;
      margin: 16px 0 0;
    }

    &__wrap {
    background:#F4F4F4;
    margin: 30px 0 42px;
    }

    &__inner {
      max-width: 800px;
      width: calc(100% - 24px * 2);
      margin: 0 auto;
      padding: 28px 0;
    }

    // 問い合わせ項目
    &-item {
      display: block;
      margin-bottom: 28px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__ttl {
        width:100%;
        font-size: 1.6rem;
        margin-bottom: 18px;
      }

      &__required {
        color:#E20000;
        font-size: 1.4rem;
        margin-left: 8px;
      }

      &__data {
        width: 100%;
        flex: 1;
      }

      &__selectbox {
        width: 184px;
        margin-bottom: 12px;
      }

      &__txt {
        font-size: 1.6rem;
        margin-left :10px;
      }

      &__error {
        color: #E20000;
        font-size: 1.3rem;

        &:first-of-type {
          margin-top: 6px;
        }
      }

    }

    // ボタン
    &-btn {
      margin: 0 auto 16px;
      max-width: 240px;

      &--primary {
        button {
          background:#3B803B;
          color:#fff;
        }
      }

      button:disabled {
        cursor: initial;
        color: #9B9B9B;
        background: #F4F4F4;
        border: solid 4px #C6C6C6;
        &:hover {
          opacity: 1;
        }
      }
    }

    // 送信完了画面
    &-end {
      &__lead {
        font-size: 1.6rem;
        line-height: 2em;
        text-align: left;
        margin: 40px 0 0;
      }

      &__wrap {
        border :4px solid #D5D5D5;
        text-align :center;
        padding: 24px 16px;
        margin: 48px 0 56px;
      }

      &__tel {
        font-size: 2.4rem;
        font-weight:bold;
        margin-bottom :16px;
      }

      &__address {
        font-size: 1.6rem;
      }
    }
  }

  // 個人情報取扱同意書
  .agreement {
    border: 1px solid #D9D9D9;
    max-width: 620px;
    width: calc(100% - 48px * 2);
    height: 260px;
    margin: 0 auto 44px;

    &__inner {
      width: calc(100% - 30px * 2);
      height: 200px;
      overflow-y: auto;
      margin: 30px auto 0;
    }

    &__ttl {
      text-align :center;
      margin-bottom: 27px;
    }

    &__txt {
      color:#7E7E7E;
      font-size: 12px;
    }

    &__link {
      text-align: center;
      margin-bottom: 32px;
      a {
        display: inline-block;
        text-decoration: underline;
      }
    }
  }

  // 同意する
  .agree {
    text-align: left;
    display: flex;
    justify-content: start;
    margin: 0 24px 50px 24px;

    &__txt {
      margin-left: 30px;
      font-size :1.4rem;
      font-weight: normal;
    }
  }
}