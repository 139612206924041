/* --------------------------------------------
   product_pc
----------------------------------------------- */

#product {
  .main {
    padding: 106px 0 120px 0;
  }

  .product {
    &__articleSearch {
      .articleSearch {
        &__type {
          margin: 0 0 120px 0;

          .type {
            &__ttl {
              margin: 0 auto 29px auto;
            }

            &__list {
              margin: -1px -1px calc(-1px + 30px) -1px;
            }

            &__item {
              width: 14.28%;
              padding: 1px;
            }
          }
        }

        &__area {
          margin: 0 0 121px 0;

          .area {
            &__ttl {
              margin: 0 auto 31px auto;
            }

            &__list {
              display: flex;
              flex-wrap: wrap;
              margin: -9px -5px calc(-9px + 30px) -5px;

              &::after {
                display: none;
              }
            }

            &__item {
              float: none;
              clear: none;
              width: 25%;
              margin: 0;
              padding: 9px 5px;
            }
          }
        }

        &__keyword {
          .keyword {
            &__ttl {
              margin: 0 auto 29px auto;
            }

            &__input {
              max-width: 596px;
            }
          }
        }
      }
    }

    &__articleList {
      margin: 0 0 120px 0;
      
      .articleList {
        &__ttl {
          margin: 0 auto 30px auto;
        }
        
        &__head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 54px 0;
        }

        &__result {
          margin: 0 auto 0 0;
        }

        &__sortOrder {
          margin: 0 0 0 auto;
        }

        &__banner {
          max-width: 600px;
          margin: 0 auto 54px auto;
        }

        &__item {
          margin: 0 0 20px 0;
        }

        &__horizontal {
          margin: -20px;

          &--left,
          &--right {
            padding: 20px;
          }

          &--left {
            width: 40.38%;
          }

          &--right {
            display: flex;
            flex-direction: column;
            width: calc(100% - 40.38%);
          }
        }

        &__desc {
          margin: 0 0 25px 0;
        }

        &__btn {
          margin: auto 0 0 0;

          .btn {
            &__items {
              margin: -8px;
            }

            &__item {
              margin: 8px;
            }
          }
        }
      }
    }

    &__articleDetail {
      .articleDetail {
        &__ttl {
          margin: 0 auto 32px auto;
        }

        &__txt {
          text-align: center;
        }

        &__img {
          margin: 0 0 80px 0;
        }

        &__feature {
          margin: 0 0 80px 0;

          .feature {
            &__ttl {
              margin: 0 auto 29px auto;
            }

            &__horizontal {
              margin: -20px;

              &--left,
              &--right {
                align-self: center;
                padding: 20px;
              }

              &--left {
                width: 40.38%;
              }

              &--right {
                width: calc(100% - 40.38%);
              }
            }
          }
        }

        &__table {
          .table {
            &__header {
              display: table-cell;
              width: 21%;
            }

            &__data {
              display: table-cell;
              width: 79%;
              text-align: left;
            }
          }
        }
      }
    }

    &__btn {
      .btn {
        &__items {
          margin: -20px;
        }

        &__item {
          margin: 20px;
        }
      }
    }
  }
}