/* --------------------------------------------
   about_sp
----------------------------------------------- */

#about {
  .main {
    padding: 56px 0 120px 0;
  }

  .about {
    &__article {
      .article {
        &__ttl {
          margin: 0 auto 24px auto;
          letter-spacing: -0.03em;
        }

        &__txt {
          margin: 0 0 24px 0;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.02em;
          color: #4A4A4A;

          &:last-child {
            margin: 0;
          }
        }

        &__img {
          width: 100%;
          max-width: 1000px;
          margin: 0 auto;

          &--chairperson {
            max-width: 203px;
            margin: 0 auto 32px auto;
          }
        }

        &__qanda {
          margin: 0 0 25px 0;
          padding: 25px 22px;
          border: solid 2px #3B803B;
          border-radius: 18px;

          &:last-child {
            margin: 0;
          }

          .qanda {
            &__ttl {
              position: relative;
              margin: 0 0 24px 0;
              padding: 48px 0 0 0;
              font-size: 1.8rem;
              font-weight: 700;
              line-height: 1.44;
              text-align: center;
              color: #3B803B;

              &::before {
                content: 'Q';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 32px;
                height: 32px;
                padding: 0 0 5px 0;
                font-size: 2rem;
                font-weight: 700;
                line-height: 0;
                color: #FFFFFF;
                background: #3B803B;
                border-radius: 100%;
                box-sizing: border-box;
              }
            }

            &__content {
              position: relative;
              padding: 48px 0 0 0;

              &::before {
                content: 'A';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 32px;
                height: 32px;
                padding: 0 0 5px 0;
                font-size: 2.2rem;
                font-weight: 700;
                line-height: 1;
                color: #FFFFFF;
                background: #3B803B;
                border-radius: 100%;
                box-sizing: border-box;
              }
            }

            &__txt {
              font-size: 1.6rem;
              font-weight: 700;
              line-height: 1.44;
              color: #333333;
            }

            &__desc {
              margin: 0 0 32px 0;

              &:last-child {
                margin: 0;
              }
            }

            &__term {
              margin: 0 0 -2px 0;
              font-size: 1.6rem;
              font-weight: 700;
              color: #333333;
            }

            &__list {
              counter-reset: qanda;
            }

            &__item {
              position: relative;
              padding: 0 0 0 25px;
              font-size: 1.6rem;
              font-weight: 500;
              line-height: 1.44;
              color: #333333;

              &::before {
                counter-increment: qanda;
                content: counter(qanda)'.';
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 700;
              }
            }

            &__table {
              overflow-x: scroll;

              thead {
                .table {
                  &__header {
                    padding: 4px;
                    font-size: 1.8rem;
                  }
                }
              }

              tbody {
                .table {
                  &__header {
                    width: 147px;
                    min-width: 147px;
                    max-width: 147px;
                    padding: 13px 17px;
                    font-size: 1.6rem;
                    font-weight: 500;
                    line-height: 1.5;
                    background: #FFFFFF;
                  }

                  &__data {
                    width: 538px;
                    min-width: 538px;
                    max-width: 538px;
                    padding: 12px 20px;
                    font-size: 1.6rem;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }

        &__name {
          margin: 52px 0 0 0;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.71;
          text-align: right;
          color: #4A4A4A;
        }

        &__head {
          margin: 0 0 20px 0;
        }

        &__lead {
          margin: 0 0 -5px 0;
          font-size: 1.8rem;
          font-weight: 700;
          text-align: center;
          color: #4A4A4A;
        }

        &__date {
          font-size: 1.8rem;
          font-weight: 700;
          text-align: center;
          color: #4A4A4A;
        }

        &__table {
          overflow-x: scroll;

          thead {
            .table {
              &__header {
                padding: 12px;
                font-size: 1.8rem;
              }
            }
          }

          tbody {
            .table {
              &__header {
                width: 70px;
                min-width: 70px;
                max-width: 70px;
                padding: 25px 25px;
                font-size: 1.8rem;
                line-height: 1.11;
                background: #FFFFFF;
              }

              &__data {
                width: 331px;
                min-width: 331px;
                max-width: 331px;
                font-size: 1.6rem;
                line-height: 1.5;

                .dataTable {
                  &__header,
                  &__data {
                    padding: 3px 19px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}